body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.force-page-break {
  display: none !important;
}

@media print {
  .force-page-break {
    display: block !important;
  }

  .print-hide-print-elem {
    display: none !important;
  }
  .print-elem {
    margin-top: 0px !important;
    width: 100% !important;
    -webkit-print-color-adjust: exact !important;
  }
  .product-list-item {
    break-inside: avoid !important;
  }
  .list-item {
    break-inside: avoid !important;
  }
}

@page {
  margin: 0;
}
